/* stylelint-disable */
.ol-mouse-position {
    position: absolute;
    right: 8px;
    top: 8px;
}

.ol-scale-line {
    background: #95b9e6;
    background: rgba(0, 60, 136, 0.3);
    border-radius: 4px;
    bottom: 8px;
    left: 8px;
    padding: 2px;
    position: absolute;
}

.ol-scale-line-inner {
    border: 1px solid #eee;
    border-top: 0;
    color: #eee;
    font-size: 10px;
    margin: 1px;
    text-align: center;
    will-change: contents, width;
}

.ol-overlay-container {
    will-change: left, right, top, bottom;
}

.ol-unsupported {
    display: none;
}

.ol-viewport .ol-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.ol-control {
    background-color: #eee;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 4px;
    padding: 2px;
    position: absolute;
}

.ol-control:hover {
    background-color: rgba(255, 255, 255, 0.6);
}

.ol-zoom {
    left: 0.5em;
    top: 0.5em;
}

.ol-rotate {
    right: 0.5em;
    top: 0.5em;
    transition: opacity 0.25s linear, visibility 0s linear;
}

.ol-rotate.ol-hidden {
    opacity: 0;
    transition: opacity 0.25s linear, visibility 0s linear 0.25s;
    visibility: hidden;
}

.ol-zoom-extent {
    left: 0.5em;
    top: 4.643em;
}

.ol-full-screen {
    right: 0.5em;
    top: 0.5em;
}

@media print {
    .ol-control {
        display: none;
    }
}

.ol-control button {
    background-color: #7b98bc;
    background-color: rgba(0, 60, 136, 0.5);
    border: 0;
    border-radius: 2px;
    color: #fff;
    display: block;
    font-size: 1.14em;
    font-weight: 700;
    height: 1.375em;
    line-height: 0.4em;
    margin: 1px;
    padding: 0;
    text-align: center;
    text-decoration: none;
    width: 1.375em;
}

.ol-control button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.ol-zoom-extent button {
    line-height: 1.4em;
}

.ol-compass {
    display: block;
    font-size: 1.2em;
    font-weight: 400;
    will-change: transform;
}

.ol-touch .ol-control button {
    font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
    top: 5.5em;
}

.ol-control button:focus, .ol-control button:hover {
    background-color: #4c6079;
    background-color: rgba(0, 60, 136, 0.7);
    text-decoration: none;
}

.ol-zoom .ol-zoom-in {
    border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
    border-radius: 0 0 2px 2px;
}

.ol-attribution {
    bottom: 0.5em;
    max-width: calc(100% - 1.3em);
    right: 0.5em;
    text-align: right;
}

.ol-attribution ul {
    color: #000;
    font-size: 0.7rem;
    line-height: 1.375em;
    margin: 0;
    padding: 0 0.5em;
    text-shadow: 0 0 2px #fff;
}

.ol-attribution li {
    display: inline;
    line-height: inherit;
    list-style: none;
}

.ol-attribution li:not(:last-child):after {
    content: ' ';
}

.ol-attribution img {
    max-height: 2em;
    max-width: inherit;
}

.ol-attribution button, .ol-attribution ul {
    display: inline-block;
}

.ol-attribution.ol-collapsed ul {
    display: none;
}

.ol-attribution.ol-logo-only ul {
    display: block;
}

.ol-attribution:not(.ol-collapsed) {
    background: rgba(255, 255, 255, 0.8);
}

.ol-attribution.ol-uncollapsible {
    border-radius: 4px 0 0;
    bottom: 0;
    height: 1.1em;
    line-height: 1em;
    right: 0;
}

.ol-attribution.ol-logo-only {
    background: 0 0;
    bottom: 0.4em;
    height: 1.1em;
    line-height: 1em;
}

.ol-attribution.ol-uncollapsible img {
    margin-top: -0.2em;
    max-height: 1.6em;
}

.ol-attribution.ol-logo-only button, .ol-attribution.ol-uncollapsible button {
    display: none;
}

.ol-zoomslider {
    background: #eee;
    background: rgba(255, 255, 255, 0.4);
    height: 200px;
    left: 0.5em;
    position: absolute;
    top: 4.5em;
    width: 24px;
}

.ol-zoomslider-thumb {
    background: #7b98bc;
    background: rgba(0, 60, 136, 0.5);
    border-radius: 2px;
    cursor: pointer;
    height: 10px;
    margin: 3px;
    position: absolute;
    width: 22px;
}

.ol-touch .ol-zoomslider {
    top: 5.5em;
    width: 2.052em;
}

.ol-touch .ol-zoomslider-thumb {
    width: 1.8em;
}

.ol-overviewmap {
    bottom: 0.5em;
    left: 0.5em;
    position: absolute;
}

.ol-overviewmap.ol-uncollapsible {
    border-radius: 0 4px 0 0;
    bottom: 0;
    left: 0;
}

.ol-overviewmap .ol-overviewmap-map, .ol-overviewmap button {
    display: inline-block;
}

.ol-overviewmap .ol-overviewmap-map {
    border: 1px solid #7b98bc;
    height: 150px;
    margin: 2px;
    width: 150px;
}

.ol-overviewmap:not(.ol-collapsed) button {
    bottom: 1px;
    left: 2px;
    position: absolute;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map, .ol-overviewmap.ol-uncollapsible button {
    display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
    background: rgba(255, 255, 255, 0.8);
}

.ol-overviewmap-box {
    border: 2px dotted rgba(0, 60, 136, 0.7);
}

.popup-label {
    background-color: #fff;
    border: 2px #444 solid;
    border-radius: 7px;
    box-shadow: 4px 4px 5px 0 rgba(50, 50, 50, 0.75);
    color: #111;
    display: none;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: bold;
    left: 50%;
    padding: 3px 6px;
    position: absolute;
    top: 10px;
    transform: translateX(-50%);
    white-space: nowrap;
}

.popup-label img {
    vertical-align: middle;
}

.popup-label.marker::before {
    border-color: transparent transparent #000;
    border-style: solid;
    border-width: 0 6px 12px;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    top: -14px;
    transform: translateX(-50%);
    width: 0;
}

.angular-openlayers-map:-moz-full-screen {
    height: 100%;
}

.angular-openlayers-map:-webkit-full-screen {
    height: 100%;
}

.angular-openlayers-map:full-screen {
    height: 100%;
}

.angular-openlayers-map:not(-moz-full-screen) {
    height: 400px;
}

.angular-openlayers-map:not(-webkit-full-screen) {
    height: 400px;
}

.angular-openlayers-map:not(full-screen) {
    height: 400px;
}

.ol-full-screen {
    position: absolute;
    top: 50%;
}
/* stylelint-enable */
