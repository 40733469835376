$header-height: 8rem;
$header-height-mobile: 6rem;
$header-height-mobile-xs: 4.6rem;

.map {
    $this: &;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    @include flex(row, wrap);

    &__wrapper {
        flex-grow: 1;
        position: relative;
        width: calc(100% - 63rem);
    }

    &__results {
        background-color: $color-white;
        width: 31rem;

        @include breakpoint(medium down) {
            @include absolute(0, null, null, 0);
            box-shadow: 0.3rem 0 3rem rgba($color-black, 0.7);
            width: 27.5rem;
            z-index: 50;
        }
    }

    &__tools {
        @include absolute(1.3rem, 4rem);
    }

    &__aside {
        background-color: $color-1--3;
        box-shadow: -2px 3px 7px rgba($color-black, 0.2);
        display: none;
        height: 100%;
        position: relative;
        width: 26rem;

        #{$this}:not(.-localiser) & {
            @include breakpoint(medium down) {
                @include absolute(0, 0);
                z-index: 4;
            }
        }
    }

    &.-show-popup {
        #{$this}__aside {
            display: block;
        }
    }

    &.-show-results {
        #{$this}__results {
            display: block;
        }
    }

    &.-large {
        height: calc(100vh - #{$header-height});
        width: 100%;

        @include breakpoint(small down) {
            height: calc(100vh - #{$header-height-mobile});

            .angular-openlayers-map {
                height: calc(100vh - #{$header-height-mobile}) !important;
            }
        }

        @include breakpoint(xsmall down) {
            height: calc(100vh - #{$header-height-mobile-xs});

            .angular-openlayers-map {
                height: calc(100vh - #{$header-height-mobile-xs}) !important;
            }
        }
    }

    .ol-zoom {
        background-color: transparent;
        left: auto;
        right: 1rem;
        top: 1rem;

        button {
            @include trs();
            background-color: $color-black;
            cursor: pointer;

            @include on-event {
                background-color: rgba($color-black, 0.75);
            }
        }
    }

    .popup-label {
        border: 1px solid $color-3--2;
        border-radius: 0;
        bottom: 6.5rem;
        box-shadow: none;
        font-weight: $normal;
        left: 0;
        padding: 1rem 2rem;
        top: auto;
        transform: none;

        &::before {
            border-color: $color-3--2 transparent transparent $color-3--2;
            border-width: 1rem;
            content: '';
            left: -1px;
            top: 100%;
            transform: none;
        }

        &::after {
            @include absolute(100%, null, null, 0);
            border-color: $color-white transparent transparent $color-white;
            border-style: solid;
            border-width: 0.85rem;
            content: '';
        }
    }
}

.background-switch {
    select {
        min-height: 3.5rem;
        background-color: $color-white;
        border: 1px solid $color-3--3;
        font-style: normal;
        padding-top: 0;
        padding-bottom: 0;
        font-family: $typo-3;
        color: $color-black;
    }
}

.map-template {
    $this: &;
    position: relative;
    width: 100%;
    font-family: $typo-3;
    @include flex(row, wrap);

    &__filter {
        display: none;
        flex-grow: 0;
        width: 31rem;
        z-index: 2;
        background: $color-3--1;
        box-shadow: 3px 0 7px #d1d1d1;

        @include breakpoint(medium down) {
            @include absolute(0, null, null, 0);
            z-index: 2;
        }

        .filters {
            height: calc(100vh - 12.6rem);
            margin-top: 0;
            width: 100%;

            @include breakpoint(small down) {
                height: calc(100vh - 10.6rem);
            }

            @include breakpoint(xsmall down) {
                height: calc(100vh - 9.2rem);
            }
        }

        &[aria-hidden='false'] {
            display: block;

            @include breakpoint(medium down) {
                box-shadow: 0.3rem 0 3rem rgba($color-black, 0.7);
                transform: none;
            }
        }

        &:not([aria-hidden='false']) {
            display: none;

            ~ #{$this}__map .-show-results {
                .map-heading {
                    padding-left: 4.5rem;
                }
            }
        }

        .map-heading {
            padding-left: 4.5rem;
        }
    }

    &__types {
        flex-grow: 0;
        width: 20rem;

        @include breakpoint(medium down) {
            display: none;
        }
    }

    &__map {
        flex-grow: 1;
        width: calc(100% - 51rem);
        .map-popup__heading {
          display: none;
        }
        .map-popup__close {
          top: .7rem;
          right: .7rem;
        }
        .popup-item-contacts {
          margin-top: 0;
        }
        .map__aside {
          width: 31rem;
        }
        .ol-zoom  {
          button {
            font-size: 1.2em;
          }
        }
    }

    &__toggle {
        @include absolute(0rem, auto, auto, 0rem);
        z-index: 10;
    }
}

.map-filter-toggle {
    $this: &;
    @include size(4.4rem);
    @include trs();
    //background-color: rgba($color-black, 0.5);
    background-color: #727272;
    border: 0;
    box-shadow: 0 0 1rem 0 rgba(#727272, 0.3);
    cursor: pointer;
    padding: 0;
    z-index: 1;


    @include on-event() {
        background-color: rgba($color-1--2, 0.8);
        box-shadow: none;
    }

    &.-is-active {
        #{$this}__line {
            &:first-child {
                margin-top: 0;
                transform: translate(-50%, -50%) rotate(45deg) scale(0.8);
            }

            &:nth-child(2) {
                opacity: 0;
                visibility: hidden;
            }

            &:last-child {
                margin-top: 0;
                transform: translate(-50%, -50%) rotate(-45deg) scale(0.8);
            }
        }
    }

    &__lines {
        @include size(100%);
        display: block;
        position: relative;
    }

    &__line {
        @extend %abs-center;
        @include trs();
        @include size(2rem, 0.2rem);
        background-color: $color-white;
        display: block;

        &:first-child {
            margin-top: -0.6rem;
        }

        &:last-child {
            margin-top: 0.6rem;
        }
    }

    &__text {
        @extend %visually-hidden;
    }
}

.map-filter {
    position: relative;

    @include breakpoint(medium down) {
        @include absolute(0, null, null, 0);
    }
}

.map-heading {
    $this: &;
    background-color: #f6f6f6;
    margin: 0;
    padding: 1.6rem 4.5rem 1.4rem 3rem;
    position: relative;

    //@include breakpoint(medium down) {
    //    padding-left: 4.5rem;
    //}

    &.-dark {
        background-color: #e6e5e5;
    }

    &__title {
        color: #727272;
        font-size: 1.4rem;
        font-weight: $normal;
        margin: 0;
        text-transform: uppercase;
    }

    &__close {
        @include trs();
        @include absolute(50%, 2rem);
        background: none;
        border: 0;
        color: #727272;
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: $bold;
        padding: 0;
        transform: translateY(-50%);

        @include on-event {
            color: $color-1--2;
        }

        &::before {
            color: currentColor;
            margin: 0;
            font-weight: $bold;
        }
    }
}

$items-length: 4;
$types-link-bg: $color-1--3;

.map-types {
    @include flex(column, wrap);
    height: calc(100vh - #{$header-height});
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        flex-basis: auto;
        flex-grow: 1;
        height: calc((100vh - #{$header-height} - 0.01px) / #{$items-length});
    }

    &__link {
        @include trs();
        @include flex(row, wrap, center, center);
        background-color: $types-link-bg;
        color: $color-white;
        font-family: $typo-2;
        font-size: 1.8rem;
        font-weight: $light;
        height: 100%;
        padding: 1rem;
        text-transform: uppercase;
        width: 100%;

        @include on-event {
            background-color: darken($types-link-bg, 7.5%);
            outline-offset: -0.2rem;
        }

        &.-is-current {
            background-color: darken($types-link-bg, 7.5%);
            font-weight: $bold;
            outline-offset: -0.2rem;
        }

    }
}

.map-search__no-results {
    position: relative;
    padding: 1rem 4rem 1rem;
    text-align: center;
    font-size: 1.8rem;
    line-height: 1.2;
    display: block;
    &::before {
        @include absolute($right: 3rem, $top: 0);
        background-color: #c5c5c5;
        content: '';
        height: 0.1rem;
        width: calc(100% - 6.5rem);
    }

    &::after {
        @include absolute($right: 3rem, $bottom: 0);
        background-color: #c5c5c5;
        content: '';
        height: 0.1rem;
        width: calc(100% - 6.5rem);
    }
}

.map-search-results {
    $this: &;
    display: none;

    &__list {
        height: calc(100vh - 12.6rem);
        list-style: none;
        margin: 0;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 3rem 0;
        font-family: $typo-3;

        @include breakpoint(small down) {
            height: calc(100vh - 10.6rem);
        }

        @include breakpoint(xsmall down) {
            height: calc(100vh - 9.2rem);
        }
    }

    &__item {
        &:not(:first-child) {
            margin-top: -0.1rem;
        }

        &.-no-results {
            font-size: 1.6rem;
            font-weight: $normal;
            padding: 0 3rem;
        }
    }

    &__btn {
        @include trs();
        background: none;
        border: 0;
        cursor: pointer;
        padding: 1rem 3rem 1rem 6rem;
        position: relative;
        text-align: left;
        width: 100%;

        &::before {
            @include absolute($right: 3rem, $top: 0);
            background-color: #c5c5c5;
            content: '';
            height: 0.1rem;
            width: calc(100% - 6.5rem);
        }

        &::after {
            @include absolute($right: 3rem, $bottom: 0);
            background-color: #c5c5c5;
            content: '';
            height: 0.1rem;
            width: calc(100% - 6.5rem);
        }

        &:focus {
            outline-offset: -0.2rem;
        }

        @include on-event {
            background-color: #c5c5c5;

            //#{$this}__category,
            //#{$this}__title {
            //    color: $color-white;
            //}
        }

        img {
            @include absolute($top: 1rem, $left: 3.8rem);
            display: block;
            height: auto;
            width: 1.5rem;
        }

    }

    &__category {
        @include trs();
        color: #ca000a;
        display: block;
        font-family: $typo-3;
        font-size: 1.2rem;
        font-weight: $bold;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    &__title {
        @include trs();
        font-family: $typo-3;
        color: #000;
        font-size: 1.3rem;
        font-weight: $light;
        margin: 0;
    }
}

.map-toggle-resultats {
    $this: &;
    @include absolute($bottom: 1.5rem, $right: 3rem);
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-family: $typo-2;
    font-size: 1.2rem;
    font-weight: $light;
    padding: 0 2rem 0 0;
    z-index: 3;
    @include icon-after(\f06e, $fw: $normal);

    @include on-event {
        &::before {
            background-color: $color-black;
        }

        &::after {
            color: $color-black;
            transform: scale(1.1);
        }
    }

    &::before {
        @include trs();
        @include absolute($top: -5%, $right: 0.5rem);
        @include size(0, 110%);
        background-color: $color-1--2;
        content: '';
        transform: rotate(-30deg);
        z-index: 2;
    }

    &::after {
        @include trs();
        @include absolute($top: 0, $right: 0);
        color: $color-1--2;
        z-index: 1;
    }

    &.-is-hidden {
        display: none;
    }

    &.-is-active {
        @include on-event {
            &::before {
                background-color: $color-black;
            }
        }

        &::before {
            background-color: $color-1--2;
            width: 2px;
        }
    }
}

.marker-message {
    white-space: normal;
    width: 20rem;

    &__theme {
        color: $color-1--3;
    }

    &__title {
        font-size: 1.4rem;
        margin: 0;
    }
}
