.localiser {
    margin: 6.5rem 0 8rem;

    .title-block {
        margin-bottom: 2.6rem;
    }

    @include breakpoint(small down) {
        margin: 5rem 0 5rem;
    }

    .title-block {
        @include breakpoint(small down) {
            display: none;
        }
    }

    &-btn {
        background: #212851;
        font-weight: $bold;
        color: $color-2--1;
        text-transform: uppercase;
        font-size: 1.6rem;
        line-height: 1.3;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 1.5rem;
        transition: all 0.2s linear;
        svg {
            width: 60px;
            height: 59px;
            fill: $color-2--1;
            flex-shrink: 0;
            margin-right: 2rem;
            transition: all 0.2s linear;
        }
        &-wrap {
            display: flex;
            justify-content: center;
            margin-top: 2rem;
        }
        i {
            align-self: flex-start;
            margin-left: 2.5rem;
            margin-top: 0.2rem;
        }
        @include on-event() {
            color: #212851;
            background: $color-2--1;
            svg {
                fill: #212851;
            }
        }
    }

    &-toggle {
        @include section-shadow();
        display: none;
        margin-bottom: 2rem;

        @include breakpoint(small down) {
            display: block;
        }

        &__btn {
            @include icon-before(\e018);
            @include trs();
            background: $color-1--3;
            border: 0;
            color: $color-2--1;
            cursor: pointer;
            font-family: $typo-1;
            font-size: 2.6rem;
            font-weight: $bold;
            padding: 2.1rem 0;
            width: 100%;

            &::before {
                content: '';
                position: absolute;
                top: 5px;
                right: 5px;
                bottom: 5px;
                left: 5px;
                border: 1px solid $color-2--1;
            }

            @include on-event() {
                background-color: $color-1--3;
            }
        }
    }
}

.map.-localiser {
    height: 57.2rem;

    @include breakpoint(small down) {
        display: none;
        flex-direction: column;
        height: auto;
    }

    &[aria-hidden='true'] {
        display: none;
    }

    &[aria-hidden='false'] {
        display: flex;
    }

    .map__wrapper,
    .map__aside {
        @include breakpoint(small down) {
            width: 100%;
        }
    }
}
