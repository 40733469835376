/* Flex Mixin */
.map-popup .map-popup__close, .map, .map-template, .map-types, .map-types__link {
    display: flex;
}

.map-popup .map-popup__close, .map, .map-template, .map-types__link {
    flex-direction: row;
}

.map-types {
    flex-direction: column;
}

.map-popup .map-popup__close, .map, .map-template, .map-types, .map-types__link {
    flex-wrap: wrap;
}

.map-popup .map-popup__close, .map-types__link {
    justify-content: center;
}

.map-popup .map-popup__close, .map-types__link {
    align-items: center;
}

[data-fa-icon]::before, .localiser-toggle__btn::before, .map-toggle-resultats::after {
    display: inline-block;
    font-family: 'Font Awesome 5 Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
}

.ghost,
.invisible, .map-filter-toggle__text {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px !important;
    left: -9999px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    top: auto !important;
    width: 1px !important;
}

.map-filter-toggle__line {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

[data-fa-icon]::before {
    content: attr(data-fa-icon);
}

[data-fa-icon]::before {
    color: #34d7b1;
    margin-right: 0.25em;
}

/* stylelint-disable */
.ol-mouse-position {
    position: absolute;
    right: 8px;
    top: 8px;
}

.ol-scale-line {
    background: #95b9e6;
    background: rgba(0, 60, 136, 0.3);
    border-radius: 4px;
    bottom: 8px;
    left: 8px;
    padding: 2px;
    position: absolute;
}

.ol-scale-line-inner {
    border: 1px solid #eee;
    border-top: 0;
    color: #eee;
    font-size: 10px;
    margin: 1px;
    text-align: center;
    will-change: contents, width;
}

.ol-overlay-container {
    will-change: left, right, top, bottom;
}

.ol-unsupported {
    display: none;
}

.ol-viewport .ol-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.ol-control {
    background-color: #eee;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    padding: 2px;
    position: absolute;
}

.ol-control:hover {
    background-color: rgba(255, 255, 255, 0.6);
}

.ol-zoom {
    left: 0.5em;
    top: 0.5em;
}

.ol-rotate {
    right: 0.5em;
    top: 0.5em;
    transition: opacity 0.25s linear, visibility 0s linear;
}

.ol-rotate.ol-hidden {
    opacity: 0;
    transition: opacity 0.25s linear, visibility 0s linear 0.25s;
    visibility: hidden;
}

.ol-zoom-extent {
    left: 0.5em;
    top: 4.643em;
}

.ol-full-screen {
    right: 0.5em;
    top: 0.5em;
}

@media print {
    .ol-control {
        display: none;
    }
}

.ol-control button {
    background-color: #7b98bc;
    background-color: rgba(0, 60, 136, 0.5);
    border: 0;
    border-radius: 2px;
    color: #fff;
    display: block;
    font-size: 1.14em;
    font-weight: 700;
    height: 1.375em;
    line-height: 0.4em;
    margin: 1px;
    padding: 0;
    text-align: center;
    text-decoration: none;
    width: 1.375em;
}

.ol-control button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.ol-zoom-extent button {
    line-height: 1.4em;
}

.ol-compass {
    display: block;
    font-size: 1.2em;
    font-weight: 400;
    will-change: transform;
}

.ol-touch .ol-control button {
    font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
    top: 5.5em;
}

.ol-control button:focus, .ol-control button:hover {
    background-color: #4c6079;
    background-color: rgba(0, 60, 136, 0.7);
    text-decoration: none;
}

.ol-zoom .ol-zoom-in {
    border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
    border-radius: 0 0 2px 2px;
}

.ol-attribution {
    bottom: 0.5em;
    max-width: calc(100% - 1.3em);
    right: 0.5em;
    text-align: right;
}

.ol-attribution ul {
    color: #000;
    font-size: 0.7rem;
    line-height: 1.375em;
    margin: 0;
    padding: 0 0.5em;
    text-shadow: 0 0 2px #fff;
}

.ol-attribution li {
    display: inline;
    line-height: inherit;
    list-style: none;
}

.ol-attribution li:not(:last-child):after {
    content: ' ';
}

.ol-attribution img {
    max-height: 2em;
    max-width: inherit;
}

.ol-attribution button, .ol-attribution ul {
    display: inline-block;
}

.ol-attribution.ol-collapsed ul {
    display: none;
}

.ol-attribution.ol-logo-only ul {
    display: block;
}

.ol-attribution:not(.ol-collapsed) {
    background: rgba(255, 255, 255, 0.8);
}

.ol-attribution.ol-uncollapsible {
    border-radius: 4px 0 0;
    bottom: 0;
    height: 1.1em;
    line-height: 1em;
    right: 0;
}

.ol-attribution.ol-logo-only {
    background: 0 0;
    bottom: 0.4em;
    height: 1.1em;
    line-height: 1em;
}

.ol-attribution.ol-uncollapsible img {
    margin-top: -0.2em;
    max-height: 1.6em;
}

.ol-attribution.ol-logo-only button, .ol-attribution.ol-uncollapsible button {
    display: none;
}

.ol-zoomslider {
    background: #eee;
    background: rgba(255, 255, 255, 0.4);
    height: 200px;
    left: 0.5em;
    position: absolute;
    top: 4.5em;
    width: 24px;
}

.ol-zoomslider-thumb {
    background: #7b98bc;
    background: rgba(0, 60, 136, 0.5);
    border-radius: 2px;
    cursor: pointer;
    height: 10px;
    margin: 3px;
    position: absolute;
    width: 22px;
}

.ol-touch .ol-zoomslider {
    top: 5.5em;
    width: 2.052em;
}

.ol-touch .ol-zoomslider-thumb {
    width: 1.8em;
}

.ol-overviewmap {
    bottom: 0.5em;
    left: 0.5em;
    position: absolute;
}

.ol-overviewmap.ol-uncollapsible {
    border-radius: 0 4px 0 0;
    bottom: 0;
    left: 0;
}

.ol-overviewmap .ol-overviewmap-map, .ol-overviewmap button {
    display: inline-block;
}

.ol-overviewmap .ol-overviewmap-map {
    border: 1px solid #7b98bc;
    height: 150px;
    margin: 2px;
    width: 150px;
}

.ol-overviewmap:not(.ol-collapsed) button {
    bottom: 1px;
    left: 2px;
    position: absolute;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map, .ol-overviewmap.ol-uncollapsible button {
    display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
    background: rgba(255, 255, 255, 0.8);
}

.ol-overviewmap-box {
    border: 2px dotted rgba(0, 60, 136, 0.7);
}

.popup-label {
    background-color: #fff;
    border: 2px #444 solid;
    border-radius: 7px;
    box-shadow: 4px 4px 5px 0 rgba(50, 50, 50, 0.75);
    color: #111;
    display: none;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: bold;
    left: 50%;
    padding: 3px 6px;
    position: absolute;
    top: 10px;
    transform: translateX(-50%);
    white-space: nowrap;
}

.popup-label img {
    vertical-align: middle;
}

.popup-label.marker::before {
    border-color: transparent transparent #000;
    border-style: solid;
    border-width: 0 6px 12px;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    top: -14px;
    transform: translateX(-50%);
    width: 0;
}

.angular-openlayers-map:-moz-full-screen {
    height: 100%;
}

.angular-openlayers-map:-webkit-full-screen {
    height: 100%;
}

.angular-openlayers-map:full-screen {
    height: 100%;
}

.angular-openlayers-map:not(-moz-full-screen) {
    height: 400px;
}

.angular-openlayers-map:not(-webkit-full-screen) {
    height: 400px;
}

.angular-openlayers-map:not(full-screen) {
    height: 400px;
}

.ol-full-screen {
    position: absolute;
    top: 50%;
}

/* stylelint-enable */
.localiser {
    margin: 6.5rem 0 8rem;
}

.localiser .title-block {
    margin-bottom: 2.6rem;
}

@media screen and (max-width: 767px) {
    .localiser {
        margin: 5rem 0 5rem;
    }
}

@media screen and (max-width: 767px) {
    .localiser .title-block {
        display: none;
    }
}

.localiser-btn {
    background: #212851;
    font-weight: 700;
    color: #e8d000;
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: 1.3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.5rem;
    transition: all 0.2s linear;
}

.localiser-btn svg {
    width: 60px;
    height: 59px;
    fill: #e8d000;
    flex-shrink: 0;
    margin-right: 2rem;
    transition: all 0.2s linear;
}

.localiser-btn-wrap {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.localiser-btn i {
    align-self: flex-start;
    margin-left: 2.5rem;
    margin-top: 0.2rem;
}

.localiser-btn:hover, .localiser-btn:focus {
    color: #212851;
    background: #e8d000;
}

.localiser-btn:hover svg, .localiser-btn:focus svg {
    fill: #212851;
}

.localiser-toggle {
    position: relative;
    display: none;
    margin-bottom: 2rem;
}

.localiser-toggle::before, .localiser-toggle::after {
    background-color: transparent;
    bottom: 2rem;
    box-shadow: 0 2rem 1.5rem 0 rgba(0, 0, 0, 0.5);
    content: '';
    display: block;
    height: 1.5rem;
    position: absolute;
    width: calc(40% - 3rem);
    z-index: -1;
}

.localiser-toggle::before {
    left: 6rem;
    transform: rotate(-3deg) skewX(-60deg);
}

@media screen and (max-width: 1279px) {
    .localiser-toggle::before {
        left: 7rem;
    }
}

.localiser-toggle::after {
    right: 6rem;
    transform: rotate(3deg) skewX(60deg);
}

@media screen and (max-width: 1279px) {
    .localiser-toggle::after {
        right: 7rem;
    }
}

@media screen and (max-width: 767px) {
    .localiser-toggle {
        display: block;
    }
}

.localiser-toggle__btn {
    transition: all 250ms ease-in-out 0ms;
    background: #212851;
    border: 0;
    color: #e8d000;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-size: 2.6rem;
    font-weight: 700;
    padding: 2.1rem 0;
    width: 100%;
}

.localiser-toggle__btn::before {
    content: "\e018";
}

.localiser-toggle__btn::before {
    content: '';
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    border: 1px solid #e8d000;
}

.localiser-toggle__btn:hover, .localiser-toggle__btn:focus {
    background-color: #212851;
}

.map.-localiser {
    height: 57.2rem;
}

@media screen and (max-width: 767px) {
    .map.-localiser {
        display: none;
        flex-direction: column;
        height: auto;
    }
}

.map.-localiser[aria-hidden='true'] {
    display: none;
}

.map.-localiser[aria-hidden='false'] {
    display: flex;
}

@media screen and (max-width: 767px) {
    .map.-localiser .map__wrapper,
    .map.-localiser .map__aside {
        width: 100%;
    }
}

.popup-item-contacts {
    margin: 2.5rem 0;
}

.popup-item-contacts:first-child {
    margin-top: 0;
}

.popup-item-contacts:last-child {
    margin-bottom: 0;
}

.popup-item-contacts__header + .popup-item-contacts__info .map-popup__close {
    top: -1.6rem;
}

.popup-item-contacts__info {
    padding: 2.5rem 3rem;
    position: relative;
}

.popup-item-contacts__image {
    display: block;
}

.popup-item-contacts__image img {
    display: block;
    height: auto;
    width: 100%;
}

.popup-item-contacts__date {
    margin: 2rem 0;
}

.popup-item-contacts__date:first-child {
    margin-top: 0;
}

.popup-item-contacts__theme, .map-popup__heading-category {
    color: #fff;
    display: block;
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}

.popup-item-contacts__title, .map-popup__heading-title {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 0;
}

.popup-item-contacts__teaser {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    margin: 1.5rem 0;
}

.popup-item-contacts__infoslist {
    margin: 1.3rem 0;
}

.popup-item-contacts__infoslist .infos,
.popup-item-contacts__infoslist .infos__wrap,
.popup-item-contacts__infoslist .infos__items {
    margin: 0;
    padding: 0;
}

.popup-item-contacts__infoslist .infos__item {
    color: #fff;
    font-size: 1.4rem;
    margin: 1.2rem 0;
}

.popup-item-contacts__infoslist .infos__item::before {
    color: #fff;
}

.popup-item-contacts__infoslist .infos__item:first-child {
    margin-top: 0;
}

.popup-item-contacts__infoslist .infos__item.-route {
    text-transform: uppercase;
}

.popup-item-contacts__infoslist .infos__item.-phone a, .popup-item-contacts__infoslist .infos__item.-address a {
    display: block;
    text-decoration: none;
}

.popup-item-contacts__infoslist .infos__item.-phone a:hover, .popup-item-contacts__infoslist .infos__item.-phone a:focus, .popup-item-contacts__infoslist .infos__item.-address a:hover, .popup-item-contacts__infoslist .infos__item.-address a:focus {
    text-decoration: underline;
}

.popup-item-contacts__actions {
    margin-top: 5.6rem;
}

.popup-item-contacts__action-btn:not(:last-child) {
    margin-bottom: 1rem;
}

.map-popup {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

.map-popup .map-popup__close {
    position: absolute;
    right: 1rem;
    top: 1.6rem;
    height: 3.3rem;
    width: 3.3rem;
    transition: all 250ms ease-in-out 0ms;
    background-color: #33b4a7;
    border-radius: 50%;
    border: 0;
    color: #212851;
    cursor: pointer;
    font-size: 1.6rem;
    padding: 0;
    z-index: 10;
}

.map-popup .map-popup__close::before {
    color: currentColor;
    margin: 0;
    line-height: 2.3;
}

.map-popup .map-popup__close:hover, .map-popup .map-popup__close:focus {
    background-color: #e8d000;
}

@media screen and (max-width: 767px) {
    .map-popup .map-popup__close {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
    }
}

.map-popup__heading-top {
    background-color: #e8d000;
    padding: 2rem 5rem 2rem 4rem;
    position: relative;
}

.map-popup__heading-top::before {
    left: 1.5rem;
    position: absolute;
    top: 1.5rem;
    color: #fff !important;
    font-size: 2rem;
}

.map-popup__heading-bottom {
    padding: 1.5rem 5rem 0 2rem;
}

.map-popup__heading-bottom .infos__item {
    color: #fff;
}

.map-popup__heading-category {
    font-size: 1rem;
    color: #fff;
}

.map-popup__heading-title {
    color: #fff;
    margin-bottom: 0;
}

@media screen and (min-width: 768px) {
    .map-popup .click-roll {
        margin-top: 2.5rem;
    }
    .map-popup .click-roll + .click-roll {
        margin-top: -10rem;
        margin-bottom: 10rem;
    }
    .map-popup .click-roll + *:not(.click-roll) {
        margin-top: -7.5rem;
    }
}

@media screen and (max-width: 767px) {
    .map-popup .click-roll {
        margin-top: 1.5rem;
    }
    .map-popup .click-roll + .click-roll {
        margin-top: -5rem;
        margin-bottom: 5rem;
    }
    .map-popup .click-roll + *:not(.click-roll) {
        margin-top: -3.5rem;
    }
    .map-popup .click-roll .click-roll__toggle::after {
        top: .5em;
    }
}

.map {
    height: 100vh;
    overflow: hidden;
    width: 100%;
}

.map__wrapper {
    flex-grow: 1;
    position: relative;
    width: calc(100% - 63rem);
}

.map__results {
    background-color: #fff;
    width: 31rem;
}

@media screen and (max-width: 1279px) {
    .map__results {
        left: 0;
        position: absolute;
        top: 0;
        box-shadow: 0.3rem 0 3rem rgba(0, 0, 0, 0.7);
        width: 27.5rem;
        z-index: 50;
    }
}

.map__tools {
    position: absolute;
    right: 4rem;
    top: 1.3rem;
}

.map__aside {
    background-color: #212851;
    box-shadow: -2px 3px 7px rgba(0, 0, 0, 0.2);
    display: none;
    height: 100%;
    position: relative;
    width: 26rem;
}

@media screen and (max-width: 1279px) {
    .map:not(.-localiser) .map__aside {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 4;
    }
}

.map.-show-popup .map__aside {
    display: block;
}

.map.-show-results .map__results {
    display: block;
}

.map.-large {
    height: calc(100vh - 8rem);
    width: 100%;
}

@media screen and (max-width: 767px) {
    .map.-large {
        height: calc(100vh - 6rem);
    }
    .map.-large .angular-openlayers-map {
        height: calc(100vh - 6rem) !important;
    }
}

@media screen and (max-width: 479px) {
    .map.-large {
        height: calc(100vh - 4.6rem);
    }
    .map.-large .angular-openlayers-map {
        height: calc(100vh - 4.6rem) !important;
    }
}

.map .ol-zoom {
    background-color: transparent;
    left: auto;
    right: 1rem;
    top: 1rem;
}

.map .ol-zoom button {
    transition: all 250ms ease-in-out 0ms;
    background-color: #000;
    cursor: pointer;
}

.map .ol-zoom button:hover, .map .ol-zoom button:focus {
    background-color: rgba(0, 0, 0, 0.75);
}

.map .popup-label {
    border: 1px solid #c5c5c5;
    border-radius: 0;
    bottom: 6.5rem;
    box-shadow: none;
    font-weight: 400;
    left: 0;
    padding: 1rem 2rem;
    top: auto;
    transform: none;
}

.map .popup-label::before {
    border-color: #c5c5c5 transparent transparent #c5c5c5;
    border-width: 1rem;
    content: '';
    left: -1px;
    top: 100%;
    transform: none;
}

.map .popup-label::after {
    left: 0;
    position: absolute;
    top: 100%;
    border-color: #fff transparent transparent #fff;
    border-style: solid;
    border-width: 0.85rem;
    content: '';
}

.background-switch select {
    min-height: 3.5rem;
    background-color: #fff;
    border: 1px solid #575c5e;
    font-style: normal;
    padding-top: 0;
    padding-bottom: 0;
    font-family: "Roboto Condensed", sans-serif;
    color: #000;
}

.map-template {
    position: relative;
    width: 100%;
    font-family: "Roboto Condensed", sans-serif;
}

.map-template__filter {
    display: none;
    flex-grow: 0;
    width: 31rem;
    z-index: 2;
    background: #f6f6f6;
    box-shadow: 3px 0 7px #d1d1d1;
}

@media screen and (max-width: 1279px) {
    .map-template__filter {
        left: 0;
        position: absolute;
        top: 0;
        z-index: 2;
    }
}

.map-template__filter .filters {
    height: calc(100vh - 12.6rem);
    margin-top: 0;
    width: 100%;
}

@media screen and (max-width: 767px) {
    .map-template__filter .filters {
        height: calc(100vh - 10.6rem);
    }
}

@media screen and (max-width: 479px) {
    .map-template__filter .filters {
        height: calc(100vh - 9.2rem);
    }
}

.map-template__filter[aria-hidden='false'] {
    display: block;
}

@media screen and (max-width: 1279px) {
    .map-template__filter[aria-hidden='false'] {
        box-shadow: 0.3rem 0 3rem rgba(0, 0, 0, 0.7);
        transform: none;
    }
}

.map-template__filter:not([aria-hidden='false']) {
    display: none;
}

.map-template__filter:not([aria-hidden='false']) ~ .map-template__map .-show-results .map-heading {
    padding-left: 4.5rem;
}

.map-template__filter .map-heading {
    padding-left: 4.5rem;
}

.map-template__types {
    flex-grow: 0;
    width: 20rem;
}

@media screen and (max-width: 1279px) {
    .map-template__types {
        display: none;
    }
}

.map-template__map {
    flex-grow: 1;
    width: calc(100% - 51rem);
}

.map-template__map .map-popup__heading {
    display: none;
}

.map-template__map .map-popup__close {
    top: .7rem;
    right: .7rem;
}

.map-template__map .popup-item-contacts {
    margin-top: 0;
}

.map-template__map .map__aside {
    width: 31rem;
}

.map-template__map .ol-zoom button {
    font-size: 1.2em;
}

.map-template__toggle {
    bottom: auto;
    left: 0rem;
    position: absolute;
    right: auto;
    top: 0rem;
    z-index: 10;
}

.map-filter-toggle {
    height: 4.4rem;
    width: 4.4rem;
    transition: all 250ms ease-in-out 0ms;
    background-color: #727272;
    border: 0;
    box-shadow: 0 0 1rem 0 rgba(114, 114, 114, 0.3);
    cursor: pointer;
    padding: 0;
    z-index: 1;
}

.map-filter-toggle:hover, .map-filter-toggle:focus {
    background-color: rgba(52, 215, 177, 0.8);
    box-shadow: none;
}

.map-filter-toggle.-is-active .map-filter-toggle__line:first-child {
    margin-top: 0;
    transform: translate(-50%, -50%) rotate(45deg) scale(0.8);
}

.map-filter-toggle.-is-active .map-filter-toggle__line:nth-child(2) {
    opacity: 0;
    visibility: hidden;
}

.map-filter-toggle.-is-active .map-filter-toggle__line:last-child {
    margin-top: 0;
    transform: translate(-50%, -50%) rotate(-45deg) scale(0.8);
}

.map-filter-toggle__lines {
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
}

.map-filter-toggle__line {
    transition: all 250ms ease-in-out 0ms;
    height: 0.2rem;
    width: 2rem;
    background-color: #fff;
    display: block;
}

.map-filter-toggle__line:first-child {
    margin-top: -0.6rem;
}

.map-filter-toggle__line:last-child {
    margin-top: 0.6rem;
}

.map-filter {
    position: relative;
}

@media screen and (max-width: 1279px) {
    .map-filter {
        left: 0;
        position: absolute;
        top: 0;
    }
}

.map-heading {
    background-color: #f6f6f6;
    margin: 0;
    padding: 1.6rem 4.5rem 1.4rem 3rem;
    position: relative;
}

.map-heading.-dark {
    background-color: #e6e5e5;
}

.map-heading__title {
    color: #727272;
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
}

.map-heading__close {
    transition: all 250ms ease-in-out 0ms;
    position: absolute;
    right: 2rem;
    top: 50%;
    background: none;
    border: 0;
    color: #727272;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0;
    transform: translateY(-50%);
}

.map-heading__close:hover, .map-heading__close:focus {
    color: #34d7b1;
}

.map-heading__close::before {
    color: currentColor;
    margin: 0;
    font-weight: 700;
}

.map-types {
    height: calc(100vh - 8rem);
    list-style: none;
    margin: 0;
    padding: 0;
}

.map-types__item {
    flex-basis: auto;
    flex-grow: 1;
    height: calc((100vh - 8rem - 0.01px) / 4);
}

.map-types__link {
    transition: all 250ms ease-in-out 0ms;
    background-color: #212851;
    color: #fff;
    font-family: "Encode Sans Expanded", sans-serif;
    font-size: 1.8rem;
    font-weight: 300;
    height: 100%;
    padding: 1rem;
    text-transform: uppercase;
    width: 100%;
}

.map-types__link:hover, .map-types__link:focus {
    background-color: #161b36;
    outline-offset: -0.2rem;
}

.map-types__link.-is-current {
    background-color: #161b36;
    font-weight: 700;
    outline-offset: -0.2rem;
}

.map-search__no-results {
    position: relative;
    padding: 1rem 4rem 1rem;
    text-align: center;
    font-size: 1.8rem;
    line-height: 1.2;
    display: block;
}

.map-search__no-results::before {
    position: absolute;
    right: 3rem;
    top: 0;
    background-color: #c5c5c5;
    content: '';
    height: 0.1rem;
    width: calc(100% - 6.5rem);
}

.map-search__no-results::after {
    bottom: 0;
    position: absolute;
    right: 3rem;
    background-color: #c5c5c5;
    content: '';
    height: 0.1rem;
    width: calc(100% - 6.5rem);
}

.map-search-results {
    display: none;
}

.map-search-results__list {
    height: calc(100vh - 12.6rem);
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 3rem 0;
    font-family: "Roboto Condensed", sans-serif;
}

@media screen and (max-width: 767px) {
    .map-search-results__list {
        height: calc(100vh - 10.6rem);
    }
}

@media screen and (max-width: 479px) {
    .map-search-results__list {
        height: calc(100vh - 9.2rem);
    }
}

.map-search-results__item:not(:first-child) {
    margin-top: -0.1rem;
}

.map-search-results__item.-no-results {
    font-size: 1.6rem;
    font-weight: 400;
    padding: 0 3rem;
}

.map-search-results__btn {
    transition: all 250ms ease-in-out 0ms;
    background: none;
    border: 0;
    cursor: pointer;
    padding: 1rem 3rem 1rem 6rem;
    position: relative;
    text-align: left;
    width: 100%;
}

.map-search-results__btn::before {
    position: absolute;
    right: 3rem;
    top: 0;
    background-color: #c5c5c5;
    content: '';
    height: 0.1rem;
    width: calc(100% - 6.5rem);
}

.map-search-results__btn::after {
    bottom: 0;
    position: absolute;
    right: 3rem;
    background-color: #c5c5c5;
    content: '';
    height: 0.1rem;
    width: calc(100% - 6.5rem);
}

.map-search-results__btn:focus {
    outline-offset: -0.2rem;
}

.map-search-results__btn:hover, .map-search-results__btn:focus {
    background-color: #c5c5c5;
}

.map-search-results__btn img {
    left: 3.8rem;
    position: absolute;
    top: 1rem;
    display: block;
    height: auto;
    width: 1.5rem;
}

.map-search-results__category {
    transition: all 250ms ease-in-out 0ms;
    color: #ca000a;
    display: block;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}

.map-search-results__title {
    transition: all 250ms ease-in-out 0ms;
    font-family: "Roboto Condensed", sans-serif;
    color: #000;
    font-size: 1.3rem;
    font-weight: 300;
    margin: 0;
}

.map-toggle-resultats {
    bottom: 1.5rem;
    position: absolute;
    right: 3rem;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-family: "Encode Sans Expanded", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    padding: 0 2rem 0 0;
    z-index: 3;
}

.map-toggle-resultats::after {
    content: "\f06e";
    font-weight: 400;
}

.map-toggle-resultats:hover::before, .map-toggle-resultats:focus::before {
    background-color: #000;
}

.map-toggle-resultats:hover::after, .map-toggle-resultats:focus::after {
    color: #000;
    transform: scale(1.1);
}

.map-toggle-resultats::before {
    transition: all 250ms ease-in-out 0ms;
    position: absolute;
    right: 0.5rem;
    top: -5%;
    height: 110%;
    width: 0;
    background-color: #34d7b1;
    content: '';
    transform: rotate(-30deg);
    z-index: 2;
}

.map-toggle-resultats::after {
    transition: all 250ms ease-in-out 0ms;
    position: absolute;
    right: 0;
    top: 0;
    color: #34d7b1;
    z-index: 1;
}

.map-toggle-resultats.-is-hidden {
    display: none;
}

.map-toggle-resultats.-is-active:hover::before, .map-toggle-resultats.-is-active:focus::before {
    background-color: #000;
}

.map-toggle-resultats.-is-active::before {
    background-color: #34d7b1;
    width: 2px;
}

.marker-message {
    white-space: normal;
    width: 20rem;
}

.marker-message__theme {
    color: #212851;
}

.marker-message__title {
    font-size: 1.4rem;
    margin: 0;
}
