// Global settings
$domain: 'corbeil-essonnes.fr';

//// Paths
//// Gets value in tasks/styles.js
//// $image-assets - ../images
//// $font-assets - ../fonts
//// $icon-assets - ../icons
$image-assets: '' !default;
$font-assets: '' !default;
$icon-assets: '' !default;
$custom-icons-assets: '' !default;
$path-root: '' !default;

// Site colors
// ## color-1
$color-1--1: #91e3d0 !default;
$color-1--2: #34d7b1 !default;
$color-1--3: #212851 !default; //+
$color-1--4: #008db5 !default; //+

// ## color-2
$color-2--1: #e8d000 !default;//+
$color-2--2: #00396f !default;//+
$color-2--3: #03213d !default;
$color-2--4: #ecf0f3 !default;
$color-2--5: #79c7c3 !default;

// ## Color-3
$color-3--1: #f6f6f6 !default; //+
$color-3--2: #c5c5c5 !default;
$color-3--3: #575c5e !default; //+
$color-3--4: #494949 !default;
$color-3--5: #33b4a7 !default; //+
$color-3--6: #79c7c3 !default; //+
$color-3--7: #dfe4e7 !default; //+

// # Helper colors
$color-black: #000;
$color-white: #fff;
$color-red: #fe0000;
$color-green: #278417;
$color-focus-outline: #ca000a;

// # Helper colors shortcuts
$white: $color-white;
$red: $color-red;
$green: $color-green;

// ## Color social networks
$socials: (
    facebook: (#3b5998, \f39e),
    twitter: (#00acee, \f099),
    instagram: (#4c68d7, \f16d),
    youtube: (#c4302b, \f167),
    linkedin: (#c4302b, \f0e1)
);

// Import fonts
$import-google-font-1: 'https://fonts.googleapis.com/css?family=Poppins:300,400,700';
$import-google-font-2: 'https://fonts.googleapis.com/css?family=Encode+Sans+Expanded:100,200,300,400,500,600,700,800,900';
$import-google-font-3: 'https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700&display=swap&subset=cyrillic,latin-ext';

// FontAwesome config
$font-awesome: 'Font Awesome 5 Pro';
$font-awesome-brands: 'Font Awesome 5 Brands';
$fa-font-path: $path-root + $font-assets;

// Site fonts // http://melchoyce.github.io/fontstacks/
$typo-1: 'Poppins', sans-serif;
$typo-2: 'Encode Sans Expanded', sans-serif;
$typo-3: 'Roboto Condensed', sans-serif;

// Font for custom icon
$icon-custom: 'icons';
$icons-font-path: $path-root + $custom-icons-assets;

// font weight
$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;
