$color-theme: $color-white;
$color-title: $color-white;

.popup-item-contacts {
    margin: 2.5rem 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__header {
        + .popup-item-contacts__info {
            .map-popup__close {
                top: -1.6rem;
            }
        }
    }

    &__info {
        padding: 2.5rem 3rem;
        position: relative;
    }

    &__image {
        display: block;

        img {
            display: block;
            height: auto;
            width: 100%;
        }
    }

    &__date {
        margin: 2rem 0;

        &:first-child {
            margin-top: 0;
        }
    }

    &__theme {
        color: $color-theme;
        display: block;
        font-family: $typo-1;
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    &__title {
        color: $color-title;
        font-family: $typo-1;
        font-size: 2rem;
        font-weight: $bold;
        margin-bottom: 0;
        margin-top: 0;
    }

    &__teaser {
        color: $color-title;
        font-family: $typo-1;
        font-size: 1.5rem;
        font-weight: $light;
        margin: 1.5rem 0;
    }

    &__infoslist {
        margin: 1.3rem 0;

        .infos,
        .infos__wrap,
        .infos__items {
            margin: 0;
            padding: 0;
        }

        .infos__item {
            color: $color-white;
            font-size: 1.4rem;
            margin: 1.2rem 0;

            &::before {
                color: $color-white;
            }

            &:first-child {
                margin-top: 0;
            }

            &.-route {
                text-transform: uppercase;
            }

            &.-phone,
            &.-address {
                a {
                    display: block;
                    text-decoration: none;

                    @include on-event() {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__actions {
        margin-top: 5.6rem;
    }

    &__action-btn {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}
$close-btn-color: $color-1--3;
$close-btn-bg: $color-1--3;
$close-btn-size: 3.3rem;
$close-btn-font-size: 1.6rem;
$close-btn-hover-bg: $color-2--1;

.map-popup {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    & &__close {
        @include absolute(1.6rem, 1rem, null, null);
        @include size($close-btn-size);
        @include flex(row, wrap, center, center);
        @include trs();
        background-color: $color-3--5;
        border-radius: 50%;
        border: 0;
        color: $close-btn-color;
        cursor: pointer;
        font-size: $close-btn-font-size;
        padding: 0;
        z-index: 10;

        &::before {
            color: currentColor;
            margin: 0;
            line-height: 2.3;
        }

        @include on-event {
            background-color: $close-btn-hover-bg;
        }

        @include breakpoint(small down) {
            @include absolute(.5rem, .5rem, null, null);
        }
    }

    &__heading-top {
        background-color: $close-btn-hover-bg;
        padding: 2rem 5rem 2rem 4rem;
        position: relative;

        &::before {
            @include absolute($top: 1.5rem, $left: 1.5rem);
            color: $color-white !important;
            font-size: 2rem;
        }

    }

    &__heading-bottom {
        padding: 1.5rem 5rem 0 2rem;

        .infos__item {
            color: $color-white;
        }
    }

    &__heading-category {
        @extend #{'.popup-item-contacts__theme'};
        font-size: 1rem;
        color: $color-white;
    }

    &__heading-title {
        @extend #{'.popup-item-contacts__title'};
        color: $color-white;
        margin-bottom: 0;
    }

    @include breakpoint (medium) {

        .click-roll {
            & {
                margin-top: 2.5rem;
            }

            & + .click-roll {
                margin-top: -10rem;
                margin-bottom: 10rem;
            }

            & + *:not(.click-roll) {
                margin-top: -7.5rem;
            }
        }
    }

    @include breakpoint (small down) {

        .click-roll {
            & {
                margin-top: 1.5rem;
            }

            & + .click-roll {
                margin-top: -5rem;
                margin-bottom: 5rem;
            }

            & + *:not(.click-roll) {
                margin-top: -3.5rem;
            }

            .click-roll__toggle::after {
                top: .5em;
            }
        }
    }
}

